import doc               from '@widesk-core/decorators/doc';
import model             from '@widesk-core/decorators/model';
import AdminServiceModel from '@/models/AdminServiceModel';
import Blamable          from '@/models/traits/Blamable';
import TimestampAble     from '@widesk-core/models/traits/TimestampAble';

export enum AdminUserStatusId {
	enabled = '1',
	disabled = '2',
	deleted = '3',
}

@model.cacheDuration()
@model.urnResource('admin_user_status')
@doc.path('/admin_user_statuses/{?id}')
export default class AdminUserStatusModel extends Blamable(TimestampAble(AdminServiceModel)) {
	private declare _tsFilters: {
		'reference': Admin_AdminUserStatusReference;
	};

	private declare _tsSorts: {
		'id': string;
		'label': string;
	};	

	@doc.string declare label: string;
	@doc.string declare reference: Admin_AdminUserStatusReference;

	public get color() {
		switch (this.reference) {
			case 'enabled':
				return 'green';
			case 'disabled':
				return 'orange';
			case 'deleted':
				return 'red';
			default:
				return 'default';
		}
	}
}