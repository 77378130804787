import { AbstractResolvableModel } from '@mathquis/modelx-resolvables';
import { ModelClass }              from '@mathquis/modelx/lib/types/collection';
import ApiModel                    from '@widesk-core/models/ApiModel';
import _get                        from 'lodash/get';
import { when }                    from 'mobx';

export type ToResolveDictionary = {
	[key: string]: {
		promise: Promise<void>;
		resolve: (value: boolean) => void;
		success: boolean;
	}
}

export interface WithWhenIsLoaded {
	calledBy?: ApiModel & WithWhenIsLoaded;
	originModel: ApiModel;
	pathToResolve: string;
	whenIsLoaded: () => Promise<void>;
}

export function ApiModelWhenIsLoaded<T extends ModelClass<AbstractResolvableModel>>(Base: T) {
	return class extends Base {
		public calledBy?: ApiModel & WithWhenIsLoaded;
		public propName?: string;
		public isReverseResolvable = false;
		public toResolveDictionary: ToResolveDictionary = {};

		// noinspection JSUnusedGlobalSymbols
		public reset(attribute: any, options = {}): this {
			setTimeout(() => {
				if (this.isLoaded) {
					const toResolveDictionary = this._toResolveDictionary;

					if (
						toResolveDictionary
						&& toResolveDictionary[this.pathToResolve]
						&& !toResolveDictionary[this.pathToResolve]!.success
					) {
						toResolveDictionary[this.pathToResolve]!.resolve(true);
						toResolveDictionary[this.pathToResolve]!.success = true;
					}
				}
			});

			return super.reset(attribute, options);
		}

		public get originModel(): ApiModel {
			if (this.calledBy) {
				if (this.calledBy.calledBy) {
					return this.calledBy.originModel;
				}
				return this.calledBy;
			}

			return this as unknown as ApiModel;
		}

		public get pathToResolve() {
			let path = '';

			if (this.calledBy) {
				path = this.calledBy.pathToResolve + '.';
			}

			return path + (this.propName || this.constructor.name);
		}

		/**
		 * Permet d'attendre que ce model et ses parents soient "isLoaded"
		 * Si un model dans la chaine ne peut pas être fetch (car pas d'id) on met fin à l'attente à ce moment
		 */
		public async whenIsLoaded() {
			const path = this.pathToResolve;
			const toResolveDictionary = this._toResolveDictionary;

			if (!toResolveDictionary) {
				return;
			}

			if (!toResolveDictionary[path]) {
				const newToResolve: any = {};
				newToResolve.promise = new Promise(resolve => {
					newToResolve.resolve = resolve;

					if (this.isLoaded) {
						resolve(true);
					}
				});

				toResolveDictionary[path] = newToResolve;
			}

			if (this.calledBy) {

				// On attend que le parent soit chargé
				await this.calledBy.whenIsLoaded();

				// On attend que le parent soit chargé
				if (this.isReverseResolvable) {
					await when(() => this._observed.isLoaded);
				}

				if (!this._observed.id || this.isReverseResolvable) { // S'il n'y a pas d'id sur le Model
					setTimeout(() => {
						toResolveDictionary[this.pathToResolve]?.resolve(true);
						delete toResolveDictionary[path];
					});

					return;
				}
			}

			await toResolveDictionary[path]?.promise;

			setTimeout(() => delete toResolveDictionary[path]);

			// Permet de mettre à jour les données du model avec celles du model observé (dans le cas où l'instance change en cours de route)
			this.set(this._observed.attributes);
		}

		private get _toResolveDictionary() {
			const originModel = (this.originModel.collection || this.originModel) as { toResolveDictionary: ToResolveDictionary };
			return originModel.toResolveDictionary;
		}

		/**
		 * L'instance d'un model peut être remplacée pendant le cycle de chargement des "resolvables"
		 * ce qui peut parfois empêcher un composant observer de se re-render
		 * Ce getter permet de récupérer la dernière instance du model à partir de sa source (qui elle ne change pas)
		 */
		private get _observed(): this {
			const origin = this.originModel;
			const path = this.pathToResolve;
			const model = _get(origin, path.replace(_get(origin, 'pathToResolve', '') + '.', ''));

			return model || this;
		}
	};
}
