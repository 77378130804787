import '@widesk-ui/components/styles/OneLineSkeleton.scss';

import AntdSkeleton from 'antd/lib/skeleton';
import View         from '@widesk-ui/components/View';

export default function OneLineSkeleton() {
	return (
		<View className="widesk-one-line-skeleton" heightF centerV minWidth={80} inline>
			<AntdSkeleton active paragraph={false} />
		</View>
	);
}
