import '@widesk-ui/components/styles/DevToolbar.scss';

import Button               from '@widesk-ui/components/Button';
import Popover              from '@widesk-ui/components/Popover';
import React                from 'react';
import View                 from '@widesk-ui/components/View';
import _groupBy             from 'lodash/groupBy';
import _minBy               from 'lodash/minBy';
import _maxBy               from 'lodash/maxBy';
import _sortBy              from 'lodash/sortBy';
import devToolbarStore      from '@widesk-core/stores/devToolbarStore';
import { Request }          from '@widesk-core/stores/devToolbarStore';
import { ClearOutlined }    from '@ant-design/icons';
import { DownOutlined }     from '@ant-design/icons';
import { UpOutlined }       from '@ant-design/icons';
import { StopOutlined }     from '@ant-design/icons';
import { PoweroffOutlined } from '@ant-design/icons';
import { observer }         from 'mobx-react';
import { useLocation }      from 'react-router-dom';
import useTheme             from '@widesk-ui/hooks/useTheme';
import _get                 from 'lodash/get';
import cacheSystem          from '@widesk-core/models/apiCacheSystem';
import { ViewProps }        from '@widesk-ui/components/View/viewTypes';

const WARN_SLOW_REQUEST = 500; // Une requête est trop lente (ms)
const WARN_SLOW_REQUESTS = 3000; // Le temps total de chargement des requêtes est trop lent (ms)
const WARN_TO_MANY_REQUESTS = 20; // Il y a trop de requête sur la page

const STORAGE_KEY = 'dev_toolbar_open';

const DevToolbar = devToolbarStore.enabled ? observer(() => {
	const [visible, setVisible] = React.useState(localStorage.getItem(STORAGE_KEY) === 'true');
	const [cacheEnabled, setCacheEnabled] = React.useState(cacheSystem.enabled);

	const location = useLocation();
	const theme = useTheme();
	const requests = devToolbarStore.requests.filter(r => r.duration);
	const cachedRequests = devToolbarStore.requests.filter(r => !r.duration);
	const consoleErrors = devToolbarStore.consoleErrors;

	const backgroundColor = '#001529';

	React.useEffect(() => devToolbarStore.clearRequests(), [location]);

	React.useEffect(() => localStorage.setItem(STORAGE_KEY, visible + ''), [visible]);

	React.useEffect(() => cacheSystem.disable(!cacheEnabled), [cacheEnabled]);

	const requestErrors = requests.filter(r => !r.response?.status);
	const slowestRequest = _maxBy(requests, r => r.duration);
	const firstStartRequest = _minBy(requests, r => r.startTime); // La première requête a être lancée
	const lastEndRequest = _maxBy(requests, r => r.endTime); // La dernière requête à se terminer
	const totalDuration = ((lastEndRequest?.endTime || 0) as number) - ((firstStartRequest?.startTime || 0) as number);

	const requestsByUrl = _groupBy(requests, r => r.response?.request?.responseURL);
	const duplicateds = Object.values(requestsByUrl).filter(values => values.length > 1)
		.map(values => [values[0] as Request]).flat();

	// Il n'y a pas de requête en erreur
	const isAllSuccess = requestErrors.length === 0;
	const isSlowRequest = (slowestRequest?.duration || 0) > WARN_SLOW_REQUEST;
	const isSlowRequests = totalDuration > WARN_SLOW_REQUESTS;
	const isToManyRequests = requests.length > WARN_TO_MANY_REQUESTS;
	const isConsoleError = !!consoleErrors.length;
	const isAllOk = isAllSuccess && !isSlowRequest && !isSlowRequests && !isToManyRequests && !isConsoleError && !duplicateds.length;

	const errorColor = theme.colorError;
	const warningColor = theme.colorWarning;

	if (!visible) {
		return (
			<View
				bg={isAllOk ? backgroundColor : (isConsoleError ? errorColor : warningColor)}
				color="white"
				absB={0}
				absR={84}
				width={50}
				height={30}
				style={{
					borderRadius: `${theme.borderRadius}px ${theme.borderRadius}px 0 0`,
					borderWidth: `2px 2px 0 2px`,
					borderStyle: 'solid',
					borderColor: theme.colorBorderSecondary,
					zIndex: 2,
				}}
			>
				<Button title="Ouvrir"
						type="link"
						onClick={() => setVisible(true)}
						style={{ color: 'white', width: '100%', height: '100%' }}>
					<UpOutlined />
				</Button>
			</View>
		);
	}

	const listRequests = (arr: Request[]) => {
		return (
			<View>
				{arr.map((r, idx) => {
					const status = _get(r, 'response.request.status');
					const message = _get(r, 'response.message');
					const method = _get(r, 'response.config.method');

					return (
						<View key={idx}>
							<View gap={10} row spread>
								<View marginT={r.startColor !== arr[idx - 1]?.startColor ? 2 : 0} width={4} bg={theme.colorPrimary}></View>

								{!!method && <View>{method.toUpperCase()}</View>}

								{!!status && <View>{status}</View>}

								<View flex={1}>
									{r.title || r.response?.request?.responseURL || r.response?.config.url}
								</View>

								<View bold color={(r.duration || 0) > WARN_SLOW_REQUEST ? warningColor : undefined}>
									{r.duration} ms
								</View>
							</View>

							{!!message && <View color={errorColor}>{message}</View>}
						</View>
					);
				})}
			</View>
		);
	};

	return (
		<View className="widesk-dev-toolbar" bg={backgroundColor} spread color="white" row style={{
			zIndex: 1,
			borderTop: `1px solid ${theme.colorBorderSecondary}`,
		}}>
			<View
				row
				splitSeparator
				gap={0}
				separatorProps={{ bg: theme.colorBorderSecondary }}
				style={{ borderRight: `1px solid ${theme.colorBorderSecondary}` }}
			>
				<ToolbarBlock
					bg={isToManyRequests ? warningColor : undefined}
					popoverTitle={`Warning si plus de ${WARN_TO_MANY_REQUESTS} requêtes`}
					popoverContent={requests.length ? listRequests(requests) : undefined}
				>
					<View>{requests.length}</View>
					<ToolbarBlockDescription>Requête{requests.length > 1 ? 's' : ''}</ToolbarBlockDescription>
				</ToolbarBlock>

				<ToolbarBlock
					popoverTitle={`Requêtes en localstorage`}
					popoverContent={cachedRequests.length ? listRequests(cachedRequests) : undefined}
				>
					<View>+ {cachedRequests.length}</View>
					<ToolbarBlockDescription>En cache</ToolbarBlockDescription>
				</ToolbarBlock>

				<ToolbarBlock
					bg={isAllSuccess ? undefined : errorColor}
					popoverContent={requestErrors.length ? listRequests(requestErrors) : undefined}
				>
					<View>{requestErrors.length}</View>
					<ToolbarBlockDescription>En erreur</ToolbarBlockDescription>
				</ToolbarBlock>

				<ToolbarBlock
					bg={isSlowRequest ? warningColor : undefined}
					popoverTitle={`Warning si plus de ${WARN_SLOW_REQUEST} ms`}
					popoverContent={listRequests(_sortBy(requests.filter(r => (r.duration || 0) > WARN_SLOW_REQUEST), r => r.duration).reverse())}
				>
					<View>{slowestRequest?.duration || 0} ms</View>
					<ToolbarBlockDescription>Requête lentes</ToolbarBlockDescription>
				</ToolbarBlock>

				<ToolbarBlock
					bg={isSlowRequests ? warningColor : undefined}
					popoverTitle={`Warning si plus de ${WARN_SLOW_REQUESTS} ms`}
				>
					<View>{totalDuration} ms</View>
					<ToolbarBlockDescription>Temps total</ToolbarBlockDescription>
				</ToolbarBlock>

				<ToolbarBlock
					bg={duplicateds.length ? warningColor : undefined}
					popoverContent={duplicateds.length ? listRequests(duplicateds) : undefined}
				>
					<View>{duplicateds.length}</View>
					<ToolbarBlockDescription>Dupliquée{duplicateds.length > 1 ? 's' : ''}</ToolbarBlockDescription>
				</ToolbarBlock>

				<ToolbarBlock
					bg={consoleErrors.length ? errorColor : undefined}
					popoverTitle={`Erreurs dans la console :`}
					popoverContent={(
						<View>
							{consoleErrors.map((err, idx) => {
								return <View color={errorColor} key={idx}>{err}</View>;
							})}
						</View>
					)}
				>
					<View>{consoleErrors.length}</View>
					<ToolbarBlockDescription>Console errors</ToolbarBlockDescription>
				</ToolbarBlock>
			</View>

			<View row center paddingH={10} marginR={80}>
				<Button
					title={(cacheEnabled ? 'Désactiver' : 'Activer') + ' le cache pour les requêtes'}
					type="link"
					onClick={() => setCacheEnabled(!cacheEnabled)}
					style={{ color: 'white' }}
				>
					{cacheEnabled ? <StopOutlined /> : <PoweroffOutlined />}
				</Button>

				<Button
					title="Vider les compteurs et le cache"
					type="link"
					onClick={() => {
						cacheSystem.clear();
						devToolbarStore.clear();
					}}
					style={{ color: 'white' }}
				>
					<ClearOutlined />
				</Button>

				<Button title="Réduire" type="link" onClick={() => setVisible(false)} style={{ color: 'white' }}>
					<DownOutlined />
				</Button>
			</View>
		</View>
	);
}) : () => null;

const ToolbarBlock = (props: React.PropsWithChildren<ViewProps> & {
	popoverContent?: React.ReactNode;
	popoverTitle?: React.ReactNode;
}) => {
	return (
		<Popover
			title={props.popoverTitle}
			content={props.popoverContent ? <View maxHeight={600} style={{ overflow: 'auto' }}>{props.popoverContent}</View> : undefined}
		>
			<div>
				<View heightF align="center" bold size={20} paddingH={20} minHeight={44} center {...props}>
					{props.children}
				</View>
			</div>
		</Popover>
	);
};

const ToolbarBlockDescription = (props: React.PropsWithChildren<ViewProps>) => {
	return <View size={12} align="center" centerV {...props}>{props.children}</View>;
};

export default DevToolbar;
