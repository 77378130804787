import _debounce         from 'lodash/debounce';
import dayjs             from 'dayjs';
import _sortBy           from 'lodash/sortBy';
import devToolbarStore   from '@widesk-core/stores/devToolbarStore';
import _has              from 'lodash/get';
import _get              from 'lodash/get';
import { AxiosResponse } from 'axios';

const LOCAL_STORAGE_ENABLED_KEY = 'api_cache_enabled';
const LOCAL_STORAGE_REQUESTS_KEY = 'api_cache_requests';
const LOCAL_STORAGE_DICT_KEY = 'api_cache_dictionary';

type Data = { '@urn': Urn; };
type DictionaryModels = Record<string, Data>;
type DictionaryRequests = Record<string, { exp: Dayjs; urns: Urn[]; totalItems: number; }>;
type CacheResult = { totalItems: number; data: Data[]; };

export class CacheSystem {
	private _dictionaryModels: DictionaryModels = {};
	private _dictionaryRequests: DictionaryRequests = {};
	private _enabled = true;

	public constructor() {
		const enabledStr = localStorage.getItem(LOCAL_STORAGE_ENABLED_KEY);

		this._enabled = enabledStr ? enabledStr === 'true' : true;

		this._dictionaryModels = JSON.parse(localStorage.getItem(LOCAL_STORAGE_DICT_KEY) || '{}') as never;
		this._dictionaryRequests = JSON.parse(localStorage.getItem(LOCAL_STORAGE_REQUESTS_KEY) || '{}') as never;
	}

	static transformObjectToCacheKey(obj: Record<string, unknown> = {}) {
		// Tri des propriétés par ordre alphabétique et création du nouvel objet trié
		const newSortedParams = _sortBy(Object.keys(obj)).reduce<Record<string, unknown>>((acc, key) => {
			acc[key] = obj[key];
			return acc;
		}, {});

		return JSON.stringify(newSortedParams);
	}

	private _transformToCacheKey(str: string) {
		return str
			.replace('https://', '')
			.replace(/[{}[\]"]/g, '')
			.replace(/,/g, '-')
			.replace(/[:/]/g, '_');
	}

	public get(requestKey: string): CacheResult | void {
		if (!this._enabled) return;

		const key = this._transformToCacheKey(requestKey);
		const request = this._dictionaryRequests[key];

		if (request) {
			if (dayjs(request.exp).isAfter()) { // On vérifie que la requête n'est pas expirée
				const data = request.urns
					.map(urn => this._dictionaryModels[urn] as Data)
					.filter(d => !!d);

				// Si au moins un model n'est pas trouvé en cache, on ignore complètement le cache
				if (data.length === request.urns.length) {
					devToolbarStore.addRequest({ response: {} as any, title: key });

					return { totalItems: request.totalItems, data };
				}
			} else {
				this._removeRequest(key);
			}
		}
	}

	public setResponse(requestKey: string, response: AxiosResponse | void, duration: number) {
		if (!response) return;

		const items = _has(response, 'data.hydra:member') ? _get(response, 'data.hydra:member', []) : [_get(response, 'data')];

		return this.set(
			requestKey,
			items,
			duration,
			_get(response, 'data.hydra:totalItems', items.length),
		);
	}

	public set(requestKey: string, data: Data[], duration: number, totalItems: number) {
		if (!duration) return;
		if (!this._enabled) return;
		if (!data.length) return; // On enregistre pas si le tableau de data est vide

		const key = this._transformToCacheKey(requestKey);
		this._dictionaryRequests[key] = {
			exp: dayjs().add(duration, 'seconds'),
			urns: data.map(d => d['@urn']),
			totalItems,
		};

		data.forEach(d => this._dictionaryModels[d['@urn']] = d);

		this._save();

		console.log('REQUESTS', this._dictionaryRequests);
		console.log('MODELS', this._dictionaryModels);
		console.log('TOTAL REQUEST', Object.keys(this._dictionaryRequests).length, duration);
	}

	public removeUrn(urn: Urn) {
		delete this._dictionaryModels[urn];
		this._save();
	}

	public removeUrnStartWith(start: string) {
		const urns = Object.keys(this._dictionaryModels).filter(key => key.startsWith(start));
		urns.forEach(urn => this.removeUrn(urn as Urn));
		// on supprime les requêtes qui contiennent un urn qui matche avec la recherche 
		const requestKeys = Object.keys(this._dictionaryRequests).filter(key => (
			this._dictionaryRequests[key]?.urns.some(urn => urn.startsWith(start)))
		);
		requestKeys.forEach(key => this._removeRequest(key));
	}

	public disable(value = true) {
		this._enabled = !value;

		if (value) {
			this.clear();
			localStorage.setItem(LOCAL_STORAGE_ENABLED_KEY, 'false');
		} else {
			localStorage.removeItem(LOCAL_STORAGE_ENABLED_KEY);
		}
	}

	public get enabled() {
		return this._enabled;
	}

	public clear() {
		this._dictionaryModels = {};
		this._dictionaryRequests = {};
		this._save();
	}

	private _removeRequest(key: string) {
		delete this._dictionaryRequests[key];
		this._save();
	}

	private _save = _debounce(() => {
		localStorage.setItem(LOCAL_STORAGE_REQUESTS_KEY, JSON.stringify(this._dictionaryRequests));
		localStorage.setItem(LOCAL_STORAGE_DICT_KEY, JSON.stringify(this._dictionaryModels));
	}, 500);
}

const cacheSystem = new CacheSystem();

export default cacheSystem;
