import WideskNavigation       from '@widesk-ui/components/Navigation';
import navigation             from '@/navigation';
import { navigationSettings } from '@/navigation';
import useTheme               from '@widesk-ui/hooks/useTheme';
import View                   from '@widesk-ui/components/View';
import Link                   from '@widesk-ui/components/Link';

export default function LayoutSider() {
	const theme = useTheme();

	return (
		<>
			<View flex style={{ overflow: 'auto' }}>
				<View center paddingT={theme.marginMD} paddingB={theme.marginXS * 1.8}>
					<Link to="/">
						<img className="app-logo" style={{width: '195px', height: '30px', maxWidth: '80%', display: 'block', margin: 'auto' }} src="/logo-white.svg" alt="" />
					</Link>
				</View>
				<View flex style={{ overflow: 'auto' }}>
					<WideskNavigation items={navigation} />
				</View>
			</View>

			<div>
				{/* Paramètres */}
				<WideskNavigation
					style={{ background: theme.colorPrimary }}
					items={navigationSettings}
				/>
			</div>
		</>
	);
}
