import 'normalize.css';
import './index.scss';
import '@/initialize';

import ReactDOM        from 'react-dom/client';
import App             from '@/App';
import devToolbarStore from '@widesk-core/stores/devToolbarStore';
import * as Sentry     from '@sentry/react';

Sentry.init({
	enabled: process.env.NODE_ENV === 'production',
	dsn: 'https://dba0cdbe6d9470c36dd1474a5fc44aa5@o240489.ingest.sentry.io/4506423317102592',
	integrations: [
		new Sentry.BrowserTracing({
			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
		}),
		new Sentry.Replay(),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (__LOCAL__) {
	const _error = console.error;
	// Affichage d'un message pour chaque erreur dans la console
	console.error = function () {
		// eslint-disable-next-line prefer-rest-params
		const args = arguments;
		devToolbarStore.addConsoleError(Array.prototype.slice.apply(args).join(' '));
		return _error.apply(console, args as any);
	};
}

ReactDOM.createRoot(document.getElementById('root')!).render(
	// Désactivation de React.StrictMode car cela déclenche plusieurs fois les requêtes
	// ce qui entraine des dysfonctionnements avec les resolvable

	// <React.StrictMode>
	<App />,
	// </React.StrictMode>,
);
