import '@widesk-ui/components/styles/Value.scss';

import React                         from 'react';
import { HTMLAttributeAnchorTarget } from 'react';
import Link                          from '@widesk-ui/components/Link';
import OneLineSkeleton               from '@widesk-ui/components/OneLineSkeleton';
import dayjs                         from 'dayjs';
import View                          from '@widesk-ui/components/View';
import Badge                         from '@widesk-ui/components/Badge';
import Button                        from '@widesk-ui/components/Button';
import { Modal }                     from '@widesk-ui/components/modal/Modal';
import formatPhoneNumber             from '@widesk-ui/tools/formatPhoneNumber';
import formatPrice                   from '@widesk-core/tools/formatPrice';
import Observer                      from '@widesk-ui/components/Observer';

export type ValueType = 'boolean' | 'date' | 'dateTime' | 'color' | 'phone' | 'email' | 'percent' | 'price' | 'html' | 'array';

type Val = React.ReactNode | Dayjs;
type ValOrFunction = Val | (() => Val);

export type ValueProps<V extends ValOrFunction> = {
	multiple?: boolean;
	style?: React.CSSProperties;
	type?: ValueType;
	linkTarget?: HTMLAttributeAnchorTarget;
	loading?: boolean;
	empty?: boolean;
} & ({
	value: V;
	link?: string;
} | {
	value: V[];
	link?: (value: V) => string;
});

export default function Value<V extends ValOrFunction>(props: ValueProps<V>) {
	const { link, empty, loading } = props;

	return (
		<Observer render={() => {
			const value = (typeof props.value === 'function' ? props.value() : props.value) as React.ReactNode;

			if (empty) return <EmptyValue />;

			if (loading) return <OneLineSkeleton />;

			if (typeof value === 'undefined' || value === '') return <EmptyValue />;

			if (Array.isArray(value)) {
				if (!value.length) return <EmptyValue />;

				return (value as V[]).map((v, index) => (
					<React.Fragment key={index}>
						<Value
							{...props as any}
							link={(link && typeof link !== 'string') ? link(v) : undefined}
							multiple={false}
							value={v}
						/>

						{index !== value.length - 1 && ', '}
					</React.Fragment>
				));
			}

			let type = props.type;

			// Quand le type n'est pas définis, on essaye de le deviner
			if (typeof type === 'undefined') {
				const uv = value as unknown;
				if (typeof uv === 'boolean') type = 'boolean';
				else if (typeof uv === 'string' && uv.startsWith('#') && uv.length === 7) type = 'color';
				else if (dayjs.isDayjs(uv)) type = 'date';
			}

			let displayedValue = value;

			switch (type) {
				case 'boolean':
					displayedValue = value ? 'Oui' : 'Non';
					break;
				case 'date':
					displayedValue = dayjs(value as never).format('L');
					break;
				case 'dateTime':
					displayedValue = dayjs(value as never).format('L LT');
					break;
				case 'color':
					displayedValue = <Badge color={value as string} text={value} />;
					break;
				case 'phone':
					displayedValue = <a href={`tel:${value}`}>{formatPhoneNumber(value as string)}</a>;
					break;
				case 'email':
					displayedValue = <a href={`mailto:${value}`}>{value}</a>;
					break;
				case 'percent':
					displayedValue = value + '%';
					break;
				case 'price':
					displayedValue = formatPrice(value as number || 0);
					break;
				case 'html':
					displayedValue = <HtmlValue value={value as string} seeMore />;
					break;
			}

			return (
				<span className={`widesk-value ${type}`} style={props.style}>
					{link ?
						<Link to={link as string} target={props.linkTarget}>{displayedValue}</Link> :
						displayedValue
					}
				</span>
			);
		}} />
	);
}

export const EmptyValue = () => <View bold color="#e0e0e0">N/A</View>;

const HtmlValue = (props: { value?: string; seeMore?: boolean; }) => {
	return (
		<View>
			<div className="type-html" dangerouslySetInnerHTML={{ __html: props.value?.toString() || '' }}></div>
			{props.seeMore && (props.value?.length || 0) > 250 && (
				<View right>
					<Button
						type="link"
						onClick={() => Modal.open({
							title: 'Texte complet',
							children: <HtmlValue value={props.value} />,
							footer: null,
							maskClosable: true,
						})}>
						Voir la suite
					</Button>
				</View>
			)}
		</View>
	);
};
