import { ModelClass }     from '@mathquis/modelx/lib/types/collection';
import { observable }     from 'mobx';
import { makeObservable } from 'mobx';
import { computed }       from 'mobx';
import { action }         from 'mobx';
import ApiModel           from '@widesk-core/models/ApiModel';

class ModelDictionary {
	private _dict = new Map<string, any>();

	@observable
	private _size = 0;

	public constructor() {
		makeObservable(this);
	}

	public get<T extends ApiModel>(urn: string): ModelClass<T> {
		const [partition, service, resource] = urn.split(':');
		return this._dict.get(ModelDictionary.getKey(partition || '', service || '', resource || ''));
	}

	@computed
	public get models() {
		return [...this._dict.values()];
	}

	public get size() {
		return this._size;
	}

	public set<T extends ApiModel>(partition: string, service: string, resource: string, model: T): void {
		this._dict.set(ModelDictionary.getKey(partition, service, resource), model);
		this.setSize(this._dict.size);
	}

	@action
	public setSize(value: number) {
		return this._size = value;
	}

	private static getKey(partition: string, service: string, resource: string): string {
		return `/${partition}:${service}:${resource}/`;
	}

	public urn(partition: string, service: string, resource: string) {
		return (target: any) => {
			console.log(
				'Registering model %c%s%c [urn: %s:%s:%s:*]',
				'color: blue; font-weight: bold',
				target.name,
				'color: inherit; font-weight: normal',
				partition, service, resource,
			);
			this.set(partition, service, resource, target);
			return target;
		};
	}
}

const modelDictionary = new ModelDictionary();

export default modelDictionary;
