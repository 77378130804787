import translationStore          from '@widesk-ui/stores/translationStore';
import modelsFr                  from '@/models/localization/fr.json';
import { setModelLocalizedData } from '@widesk-core/mixins/ApiModelLocalizedLabel';
import { setLocale }             from '@widesk-core/locale';

setLocale('fr');

setModelLocalizedData(modelsFr);

const translations = {
	fr: { 'title': 'Français', 'image': '/flags/fr.png' },
	en: { 'title': 'Anglais', 'image': '/flags/en.png' },
};

translationStore.setTranslations(translations);
