import '../styles/FormItem.scss';

import AntdForm     from 'antd/lib/form';
import React        from 'react';
import useForm      from '@widesk-ui/hooks/useForm';
import { FormRule } from 'antd';

type FormItemProps = {
	label?: string;
	name: string | string[];
	initialValue?: unknown;
	children: React.ReactElement<FieldProps>;
	required?: boolean;
	rules?: FormRule[];
}

export function FormItem(props: FormItemProps) {
	const { children, name, label, initialValue, required } = props;

	const { fields } = useForm();
	const field = (fields || {})[Array.isArray(name) ? '' : name];

	const updatedChildren = React.Children.map(children, child => {
		if (React.isValidElement(child)) {
			return React.cloneElement(child, {
				disabled: child.props.disabled || field?.disabled,
				size: 'large',
				placeholder: child.props.placeholder || field?.label,
				style: { width: '100%', ...child.props.style },
			});
		}
		return child;
	});

	return (
		<AntdForm.Item
			className="widesk-form-item"
			name={name}
			label={label}
			initialValue={initialValue}
			rules={[{ required, message: 'Veuillez renseigner cette information' }, ...(props.rules || []) ]}
		>
			{updatedChildren}
		</AntdForm.Item>
	);
}
