import UserStatusModel     from '@models/account/UserStatusModel';
import doc                 from '@widesk-core/decorators/doc';
import model               from '@widesk-core/decorators/model';
import AccountServiceModel from '@/models/AccountServiceModel';
import TimestampAble       from '@widesk-core/models/traits/TimestampAble';
import Blamable            from '@/models/traits/Blamable';

@model.urnResource('user')
@doc.path('/users/{?id}')
export default class UserModel extends Blamable(TimestampAble(AccountServiceModel)) {
	private declare _tsFilters: {
		'userAccounts': id;
		'userStatus': id;
		'userStatus.reference': Account_UserStatusReference;
		'userStatusHistories': id;
	};

	private declare _tsSorts: {
		'firstname': string;
		'id': string;
		'lastname': string;
	};

	@doc.string declare email: string;
	@doc.string declare firstname: string;
	@doc.string declare lastname: string;
	@doc.string declare password: string;
	@doc.string declare plainPassword: string;
	@doc.model(UserStatusModel) declare userStatus: UserStatusModel;

	public get fullName() {
		return [this.lastname, this.firstname].join(' ');
	}
}