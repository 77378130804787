import ApiConnector from '@/models/ApiConnector';
import ServiceModel from '@models/ServiceModel';

const service = window.APP_CONFIG.services.find(service => service.id === 'audit');

export const auditConnector = new ApiConnector({ baseURL: service?.configurations.api_endpoint });

export default class AuditServiceModel extends ServiceModel {
	public static get connector() {
		return auditConnector;
	}

	public static serviceName = 'audit';
	public static partitionName = '$';
}
