import View               from '@widesk-ui/components/View';
import useTheme           from '@widesk-ui/hooks/useTheme';
import LayoutHeaderLogout from '@widesk-ui/components/LayoutHeaderLogout';
import ViewPrimary        from '@widesk-ui/components/ViewPrimary';
import { useNavigate }    from 'react-router-dom';
import useThemeMode       from '@widesk-ui/hooks/useThemeMode';
import appStore           from '@/stores/appStore';
import ButtonActions      from '@widesk-ui/components/ButtonActions';

export default function LayoutHeader() {
	const { marginLG, marginXS } = useTheme();
	const navigate = useNavigate();

	return (
		<View row spread>
			<ViewPrimary
				paddingH={marginLG}
				paddingV={marginXS * 1.5}
				separatorL
				separatorProps={{ bg: 'white' }}
				onClick={() => navigate('/')}
				row={false}
				lineHeight={1.2}
			>
				<View size={20} bold>Tokamap</View>
				<View size={14}>Pilotage</View>
			</ViewPrimary>

			<View row>
				<UserHeaderSettings />

				<LayoutHeaderLogout />
			</View>
		</View>
	);
}

const UserHeaderSettings = () => {
	const { mode, setMode } = useThemeMode();
	const { colorPrimary } = useTheme();
	const isDark = mode === 'dark';
	
	return (
		<View bg={colorPrimary} color="white" center minWidth={150}>
			<ButtonActions
				actions={[
					{
						label: 'Afficher le thème ' + (isDark ? 'clair' : 'sombre'),
						onClick: () => setMode(isDark ? 'light' : 'dark'),
					},
				]}
				icon={appStore.user.renderName()}
				type="text"
				block
				style={{ color: 'white', height: '100%', borderRadius: 0 }}
			/>
		</View>
	);
};
