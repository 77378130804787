import ApiModel        from '@widesk-core/models/ApiModel';
import modelDictionary from '@widesk-core/models/ModelDictionary';

export default (partition: '$', service: string, resource: string) => {
	return (target: ApiModel) => {
		Object.defineProperty(target, 'urnData', {
			configurable: false,
			enumerable: false,
			value: { partition, resource, service },
			writable: false,
		});

		return modelDictionary.urn(partition, service, resource)(target);
	};
};
