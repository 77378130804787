import Dropdown             from 'antd/lib/dropdown';
import { MenuProps }        from 'antd/lib/menu';
import Space                from 'antd/lib/space';
import Spin                 from 'antd/lib/spin';
import _omit                from 'lodash/omit';
import React                from 'react';
import DisabledTooltip      from '@widesk-ui/components/DisabledTooltip';
import { Link }             from 'react-router-dom';
import Tooltip              from '@widesk-ui/components/Tooltip';
import { ButtonProps }      from '@widesk-ui/components/Button';
import Button               from '@widesk-ui/components/Button';
import { Modal }            from '@widesk-ui/components/modal/Modal';
import { ModalMethodProps } from '@widesk-ui/components/modal/Modal';
import useForceUpdate       from '@widesk-core/hooks/useForceUpdate';
import devToolbarStore      from '@widesk-core/stores/devToolbarStore';
import { message }          from '@widesk-ui/hooks/useMessage';

import './styles/ButtonActions.scss';

export type Action = {
	confirm?: Partial<ModalMethodProps> | string;
	danger?: boolean;
	primary?: boolean;
	disabled?: boolean;
	disabledMessage?: string | string[];
	disabledOptions?: React.ComponentProps<typeof DisabledTooltip>;
	hidden?: boolean;
	icon?: React.ReactNode;
	label: React.ReactNode;
	link?: string;
	linkTarget?: '_self' | '_blank' | '_parent' | '_top';
	loading?: boolean;
	onClick?: (list?: any[]) => Promise<any> | void;
	successMessage?: string;
}

interface ButtonActionsProps extends Partial<ButtonProps> {
	actions: Action[];
	forceButton?: boolean;
	forceDropdown?: boolean;
	stopPropagation?: boolean;
	size?: ComponentSize;
}

export default function ButtonActions(props: ButtonActionsProps) {
	const forceUpdate = useForceUpdate();

	const { actions, forceButton, forceDropdown, icon, stopPropagation } = props;

	const buttonProps = _omit(props, ['actions', 'stopPropagation', 'forceButton', 'forceDropdown']);

	const loading = actions.some(action => action.loading);

	if (!actions.length) {
		return null;
	}

	const _execAction = async (action: Action) => {
		if (action.onClick) {
			devToolbarStore.clearRequests();

			action.loading = true;
			forceUpdate();
			try {
				await action.onClick();
				if (action.successMessage) {
					message.success(action.successMessage);
				}
			} finally {
				action.loading = false;
				forceUpdate();
			}
		}
	};

	const _onClick = (e: React.MouseEvent<HTMLElement>, action: Action) => {
		if (props.stopPropagation) {
			e.stopPropagation();
		}

		if (action.onClick) {
			if (action.confirm) {
				const confirmProps = typeof action.confirm === 'string' ? { title: action.confirm } : action.confirm;

				Modal.confirm({
					onOk: () => _execAction(action),
					title: 'Attention !',

					...confirmProps,
				});
			} else {
				return _execAction(action);
			}
		}
	};

	if (forceButton || (actions.length === 1 && !forceDropdown && actions[0]?.icon && !actions[0].link)) {
		return (
			<Space className="widesk-button-actions">
				{actions.map((action, idx) => {
					const icon = action.loading ? <Spin size="small" style={{ marginRight: 4 }} /> : action.icon;

					return (
						<Tooltip key={idx} title={action.label}>
							<div>
								<DisabledTooltip
									disabled={action.disabled}
									messages={action.disabledMessage}
									placement="bottomRight"

									{...action.disabledOptions}
								>
									<Button
										{...buttonProps}
										type={action.primary ? 'primary' : buttonProps.type}
										danger={action.danger && !action.disabled}
										disabled={action.disabled}
										icon={forceButton ? icon : undefined}
										onClick={e => _onClick(e as any, action)}
									>
										{(buttonProps.icon || forceButton) ? action.label : icon}
									</Button>
								</DisabledTooltip>
							</div>
						</Tooltip>
					);
				})}
			</Space>
		);
	}

	const items: MenuProps['items'] = actions
		.filter(action => !action.hidden)
		.map((action, idx) => {
			const icon = action.loading ? <Spin size="small" style={{ marginRight: 4 }} /> : action.icon;

			return {
				danger: action.danger && !action.disabled,
				disabled: action.disabled,
				key: idx,
				label: (
					<DisabledTooltip
						disabled={action.disabled}
						messages={action.disabledMessage}
						placement="rightTop"

						{...action.disabledOptions}
					>
						{(!action.disabled && action.link) ? (
							<Link target={action.linkTarget} to={action.link}>
								{icon} {action.label}
							</Link>
						) : (
							<span style={{ whiteSpace: 'nowrap' }}>{icon} {action.label}</span>
						)}
					</DisabledTooltip>
				),
				onClick: m => _onClick(m.domEvent as never, action),
			};
		});

	return (
		<Dropdown
			className="widesk-button-actions"
			overlayClassName="widesk-button-actions-overlay"
			menu={{ items }}
			trigger={['click']}
		>
			<Button onClick={e => stopPropagation && e.stopPropagation()} {...buttonProps} icon={null}>
				{loading ? <Spin size="small" /> : (icon || `...`)}
			</Button>
		</Dropdown>
	);
}
