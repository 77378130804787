import Space            from 'antd/lib/space';
import _omit            from 'lodash/omit';
import React            from 'react';
import Tooltip          from '@widesk-ui/components/Tooltip';
import { TooltipProps } from '@widesk-ui/components/Tooltip';
import useTheme         from '@widesk-ui/hooks/useTheme';

const interleave = (arr: any, thing: any) => [].concat(...arr.map((n: any) => [n, thing])).slice(0, -1);

const getMessage = ({ disabled, messages, operator, title }: DisabledTooltipProps, color: string) => {
	if (disabled && messages) {
		if (Array.isArray(messages)) {

			const operatorLabel = operator === 'or' ? 'OU' : 'ET';

			const messagesFormatted = interleave(messages, (
				<strong style={{ color }}>{operatorLabel}&nbsp;</strong>
			));

			return (
				<Space align="center" direction="vertical">
					<strong style={{ fontSize: 14, textDecoration: 'underline' }}>
						{title || `Conditions d'activation :`}
					</strong>

					<Space direction="vertical" size={1} style={{ alignItems: 'center', alignSelf: 'center' }}>
						{messagesFormatted.map((message, i) => (
							<div key={i} style={{ fontSize: 12, textAlign: 'center' }}>
								{message}
							</div>
						))}
					</Space>
				</Space>
			);
		}

		return <span style={{ fontSize: 12 }}>{messages}</span>;
	}

	return undefined;
};

export type DisabledTooltipProps = {
	disabled?: boolean;
	messages?: string | string[];
	operator?: 'and' | 'or';
	title?: string | React.ReactNode;
} & Partial<TooltipProps>;

const DisabledTooltip = (props: DisabledTooltipProps) => {
	const { colorPrimary } = useTheme();

	return (
		<Tooltip
			{..._omit(props, ['disabled', 'messages'])}
			title={getMessage(props, colorPrimary)}
		>
			<div>
				{props.children}
			</div>
		</Tooltip>
	);
};

export default DisabledTooltip;
