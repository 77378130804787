import React           from 'react';
import Text            from '@widesk-ui/components/Text';
import theme           from 'antd/lib/theme';
import { GlobalToken } from 'antd/lib/theme';
import { ViewProps }   from '@widesk-ui/components/View/viewTypes';
import { MarginValue } from '@widesk-ui/components/View/viewTypes';
import { Props }       from '@widesk-ui/components/View/viewTypes';

const { useToken } = theme;

const styleFromViewProps = (props: ViewProps, token: GlobalToken) => {
	const getMarginValue = (value?: MarginValue | number) => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const res = value ? token[`margin${value.toString().toUpperCase()}`] as number : 0;
		return res || value;
	};

	const separatorColor = props.separatorProps?.bg || token.colorBorderSecondary;

	const style: React.CSSProperties = {
		zIndex: props.zIndex,
		backgroundColor: props.bg,
		borderRadius: props.br,
		display: 'flex',
		flex: (typeof props.flex === 'boolean') ? 1 : props.flex,
		flexDirection: props.row ? 'row' : 'column',
		gap: getMarginValue(props.gap),
		height: props.height || props.h,
		marginBottom: getMarginValue(props.marginB || props.mb),
		marginLeft: getMarginValue(props.marginL || props.ml),
		marginRight: getMarginValue(props.marginR || props.mr),
		marginTop: getMarginValue(props.marginT || props.mt),
		maxHeight: props.maxHeight || props.maxH,
		maxWidth: props.maxWidth || props.maxW,
		minHeight: props.minHeight || props.minH,
		minWidth: props.minWidth || props.minW,
		paddingBottom: getMarginValue(props.paddingB || props.pb),
		paddingLeft: getMarginValue(props.paddingL || props.pl),
		paddingRight: getMarginValue(props.paddingR || props.pr),
		paddingTop: getMarginValue(props.paddingT || props.pt),
		width: props.width || props.w,
	};

	if (props.rel) {
		style.position = 'relative';
	}

	if (props.noWrap) {
		style.whiteSpace = 'nowrap';
	}

	if (props.inline) {
		style.display = 'inline-flex';
	}

	if (props.gapV) {
		style.rowGap = getMarginValue(props.gapV);
	}

	if (props.gapH) {
		style.columnGap = getMarginValue(props.gapH);
	}

	if (props.wrap) {
		style.flexWrap = 'wrap';
	}

	if (props.pointer || props.onClick) {
		style.cursor = 'pointer';
	}

	if (props.hidden) {
		style.display = 'none';
	}

	if (props.widthF) {
		style.width = '100%';
	}

	if (props.minWidthF) {
		style.minWidth = '100%';
	}

	if (props.maxWidthF) {
		style.maxWidth = '100%';
	}

	if (props.minHeightF) {
		style.minHeight = '100%';
	}

	if (props.maxHeightF) {
		style.maxHeight = '100%';
	}

	if (props.heightF) {
		style.height = '100%';
	}

	const margin = props.margin || props.m;
	const marginH = props.marginH || props.mx;
	const marginV = props.marginV || props.my;

	// noinspection DuplicatedCode
	if (margin) {
		style.margin = getMarginValue(margin);
	} else {
		if (marginH) {
			style.marginLeft = getMarginValue(marginH);
			style.marginRight = getMarginValue(marginH);
		}

		if (marginV) {
			style.marginTop = getMarginValue(marginV);
			style.marginBottom = getMarginValue(marginV);
		}
	}

	const padding = props.padding || props.p;
	const paddingH = props.paddingH || props.px;
	const paddingV = props.paddingV || props.py;

	// noinspection DuplicatedCode
	if (padding) {
		style.padding = getMarginValue(padding);
	} else {
		if (paddingH) {
			style.paddingLeft = getMarginValue(paddingH);
			style.paddingRight = getMarginValue(paddingH);
		}

		if (paddingV) {
			style.paddingTop = getMarginValue(paddingV);
			style.paddingBottom = getMarginValue(paddingV);
		}
	}

	const centerH = props.center || props.centerH;
	const centerV = props.center || props.centerV;

	if (centerH) {
		if (props.row) {
			style.justifyContent = 'center';
		} else {
			style.alignItems = 'center';
		}
	}

	if (centerV) {
		if (props.row) {
			style.alignItems = 'center';
		} else {
			style.justifyContent = 'center';
		}
	}

	if (props.bottom) {
		if (props.row) {
			style.alignItems = 'flex-end';
		} else {
			style.justifyContent = 'flex-end';
		}
	}

	if (props.right) {
		if (props.row) {
			style.justifyContent = 'flex-end';
		} else {
			style.alignItems = 'flex-end';
		}
	}

	if (props.left) {
		if (props.row) {
			style.justifyContent = 'flex-start';
		} else {
			style.alignItems = 'flex-start';
		}
	}

	if (props.top) {
		if (props.row) {
			style.alignItems = 'flex-start';
		} else {
			style.justifyContent = 'flex-start';
		}
	}

	if (props.spread) {
		style.justifyContent = 'space-between';
	}

	if (props.space) {
		style.justifyContent = 'space-between';
		style.gap = props.space;
	}

	if (Object.keys(props).some(name => name.startsWith('abs') && props[name as never] !== false)) {
		style.position = 'absolute';
	}

	if (typeof props.absL !== 'undefined') {
		style.left = props.absL;
	}

	if (typeof props.absR !== 'undefined') {
		style.right = props.absR;
	}

	if (typeof props.absT !== 'undefined') {
		style.top = props.absT;
	}

	if (typeof props.absB !== 'undefined') {
		style.bottom = props.absB;
	}

	if (props.absV) {
		style.height = '100%';
	}

	if (props.absH) {
		style.width = '100%';
	}

	if (props.absF) {
		style.height = '100%';
		style.width = '100%';
	}

	if (props.separatorB) {
		style.borderBottom = `1px solid ${separatorColor}`;
	}

	if (props.separatorT) {
		style.borderTop = `1px solid ${separatorColor}`;
	}

	if (props.separatorR) {
		style.borderRight = `1px solid ${separatorColor}`;
	}

	if (props.separatorL) {
		style.borderLeft = `1px solid ${separatorColor}`;
	}

	return style;
};

const interleave = (arr: any, c: (k: string) => React.ReactNode) => [].concat(...arr.map((n: any, k: any) => [n, c(k)])).slice(0, -1);

const styleFromProps = (props: Props, token: GlobalToken) => ({
	...Text.styleFromTextProps(props),
	...styleFromViewProps(props, token),
	...props.style,
});

const View = (props: React.PropsWithChildren<Props>) => {
	const [isHover, setIsHover] = React.useState(false);

	const { token } = useToken();

	let children = props.children;

	if (props.splitSeparator) {
		children = interleave(React.Children.toArray(props.children), k => (
			<View
				bg={token.colorBorderSecondary}
				height={props.row ? undefined : 1}
				key={'VIEW_SEPARATOR_' + k}
				marginH={props.row ? (typeof props.gap === 'undefined' ? 10 : props.gap) : 0}
				marginV={props.row ? 0 : (typeof props.gap === 'undefined' ? 10 : props.gap)}
				width={props.row ? 1 : undefined}
				{...props.separatorProps}
			/>
		));
	}

	if (props.split) {
		children = interleave(React.Children.toArray(props.children), props.split);
	}

	const transition = typeof props.transition === 'undefined' ? true : props.transition;

	const className = Array.isArray(props.className) ? props.className.filter(v => v !== '').join(' ') : props.className;

	const setIsHoverMethod = (value: boolean) => {
		setIsHover(value);

		if (props.onHover) {
			props.onHover(value);
		}
	};

	const isHoverEnabled = !!props.hover || props.onHover;

	return (
		<div
			id={props.id}
			className={className}
			onClick={props.onClick}
			onAuxClick={props.onAuxClick}
			onKeyDown={props.onKeyDown}
			onKeyPress={props.onKeyPress}
			onKeyUp={props.onKeyUp}
			onMouseUp={props.onMouseUp}
			onMouseDown={props.onMouseDown}
			onMouseEnter={isHoverEnabled ? () => setIsHoverMethod(true) : undefined}
			onMouseLeave={isHoverEnabled ? () => setIsHoverMethod(false) : undefined}
			style={{
				...((props.hover && isHover) ? styleFromProps({ ...props, ...props.hover }, token) : styleFromProps(props, token)),
				transition: transition ? 'all 0.3s ease' : undefined,
			}}
		>
			{children}
		</div>
	);
};

export default View;
