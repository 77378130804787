import doc                 from '@widesk-core/decorators/doc';
import model               from '@widesk-core/decorators/model';
import AccountServiceModel from '@/models/AccountServiceModel';
import TimestampAble       from '@widesk-core/models/traits/TimestampAble';
import Blamable            from '@/models/traits/Blamable';

export enum AccountStatusId {
	enabled = '1',
	disabled = '2',
}

@model.cacheDuration()
@model.urnResource('account_status')
@doc.path('/account_statuses/{?id}')
export default class AccountStatusModel extends Blamable(TimestampAble(AccountServiceModel)) {
	private declare _tsFilters: {
		'accountStatusHistories': id;
		'accounts': id;
		'reference': Account_AccountStatusReference;
	};

	private declare _tsSorts: {
		'id': string;
		'label': string;
	};	

	@doc.string declare label: string;
	@doc.string declare reference: Account_AccountStatusReference;
	
	public get color() {
		switch (this.reference) {
			case 'enabled':
				return 'green';
			case 'disabled':
				return 'orange';
			default:
				return 'default';
		}
	}
}