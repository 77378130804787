import '@widesk-ui/components/styles/Tag.scss';

import AntdTag from 'antd/lib/tag';
import React   from 'react';

export type TagProps = {
	color?: string;
	bordered?: boolean;
	icon?: React.ReactNode;
} & React.PropsWithChildren;

export default function Tag(props: TagProps) {
	return (
		<AntdTag
			className="widesk-tag"
			color={props.color}
			bordered={props.bordered}
			icon={props.icon}
		>
			{props.children}
		</AntdTag>
	);
}
