import '@widesk-ui/styles/AuthLayout.scss';

import AntdLayout             from 'antd/lib/layout';
import DevToolbar             from '@widesk-ui/components/DevToolbar';
import EnvHeadband            from '@widesk-ui/components/EnvHeadband';
import Page                   from '@widesk-ui/components/Page';
import React                  from 'react';
import { SetStateAction }     from 'react';
import { Dispatch }           from 'react';
import View                   from '@widesk-ui/components/View';
import { ModalProvider }      from '@widesk-ui/components/modal/ModalProvider';
import { Outlet }             from 'react-router-dom';
import useDidMount            from '@widesk-core/hooks/useDidMount';
import useMessage             from '@widesk-ui/hooks/useMessage';
import useNavigate            from '@widesk-ui/hooks/useNavigate';
import useTheme               from '@widesk-ui/hooks/useTheme';

export type AuthLayoutProps = {
	renderHeader?: () => React.ReactNode;
	renderSider?: (collapsedState: [boolean, Dispatch<SetStateAction<boolean>>]) => React.ReactNode;
	renderTopPage?: () => React.ReactNode;
	onDidMount?: () => void;
	siderWidth?: number;
	siderCollapsedWidth?: number;
	initialCollapsed?: boolean; 
	disableTrigger?: boolean;
	fullWidthHeader?: boolean;
}

export default function AuthLayout(props: AuthLayoutProps) {
	useNavigate();

	const theme = useTheme();
	const [contextHolder] = useMessage();

	const [collapsed, setCollapsed] = React.useState(!!props.initialCollapsed);
	const [isReady, setIsReady] = React.useState(false);

	const siderWidth = props.siderWidth || 300;
	const siderCollapsedWidth = props.siderCollapsedWidth || 80;

	// On attend que le composant soit monté pour l'afficher afin d'éviter une dislocation
	useDidMount(() => setIsReady(true));

	useDidMount(() => props.onDidMount ? props.onDidMount() : undefined);

	return (
		<View height="100vh" bg={theme.colorBgContainer} color={theme.colorTextBase}>
			{contextHolder}

			<AntdLayout className="widesk-auth-layout" style={{ height: '100%', ...(isReady ? {} : { visibility: 'hidden' }) }}>
				{props.fullWidthHeader && !!props.renderHeader && (
					<AntdLayout.Header style={{ padding: 0 }}>
						{props.renderHeader()}
					</AntdLayout.Header>
				)}
				
				{!props.fullWidthHeader && !!props.renderSider && (
					<AntdLayout.Sider trigger={props.disableTrigger ? null : undefined} collapsible collapsed={collapsed} onCollapse={value => setCollapsed(value)} width={siderWidth} collapsedWidth={siderCollapsedWidth}>
						{props.renderSider([collapsed, setCollapsed])}
					</AntdLayout.Sider>
				)}

				<AntdLayout>
					{!props.fullWidthHeader && !!props.renderHeader && (
						<AntdLayout.Header style={{ padding: 0 }}>
							{props.renderHeader()}
						</AntdLayout.Header>
					)}
					
					{props.fullWidthHeader && !!props.renderSider && (
						<AntdLayout.Sider trigger={props.disableTrigger ? null : undefined} collapsible collapsed={collapsed} onCollapse={value => setCollapsed(value)} width={siderWidth} collapsedWidth={siderCollapsedWidth}>
							{props.renderSider([collapsed, setCollapsed])}
						</AntdLayout.Sider>
					)}

					<AntdLayout.Content>
						<Page>
							{!!props.renderTopPage && props.renderTopPage()}

							<Outlet />
						</Page>
					</AntdLayout.Content>
				</AntdLayout>

				<EnvHeadband />

				<ModalProvider />
			</AntdLayout>

			<DevToolbar />
		</View>
	);
}
