import { observable }     from 'mobx';
import { makeObservable } from 'mobx';
import { action }         from 'mobx';
import { computed }       from 'mobx';
import { jwtDecode }      from 'jwt-decode';

const TOKEN_KEY = 'token';
const REFRESH_TOKEN_KEY = 'refresh_token';

type RefreshTokenRequestReturn = Promise<{ token: string; refreshToken: string }>;

let refreshTokenPromise: undefined | RefreshTokenRequestReturn;

type Storage = {
	setItem: (key: string, value: string) => void;
	getItem: (key: string) => string | null;
	removeItem: (key: string) => void;
};

export class AuthStore {
	@observable private _token: string = '';
	@observable private _refreshToken: string = '';

	protected readonly _storage: Storage;

	private _refreshTokenRequest: () => RefreshTokenRequestReturn = async () => ({ token: '', refreshToken: '' });

	constructor(storage: Storage = localStorage) {
		this._storage = storage;
		this._token = storage.getItem(TOKEN_KEY) || '';
		this._refreshToken = storage.getItem(REFRESH_TOKEN_KEY) || '';

		makeObservable(this);
	}

	setRefreshToken(newToken: string) {
		if (newToken) {
			this._storage.setItem(REFRESH_TOKEN_KEY, newToken);
		} else {
			this._storage.removeItem(REFRESH_TOKEN_KEY);
		}
		this._refreshToken = newToken;
	}

	@action setToken(newToken: string) {
		if (newToken) {
			this._storage.setItem(TOKEN_KEY, newToken);
		} else {
			this._storage.removeItem(TOKEN_KEY);
		}
		this._token = newToken;
	}

	@computed get tokenData(): any {
		return jwtDecode(this._token) || {};
	}

	@computed get exp() {
		return this.tokenData.exp as number;
	}

	@computed get token() {
		return this._token;
	}

	public isExpired() {
		return isExpired(this.exp);
	}

	public get refreshToken() {
		return this._refreshToken;
	}

	public clear() {
		this.setToken('');
		this.setRefreshToken('');
	}

	public setRefreshTokenRequest(request: () => RefreshTokenRequestReturn) {
		this._refreshTokenRequest = request;
	}

	public async refreshTokenAsync() {
		if (!refreshTokenPromise) {
			refreshTokenPromise = new Promise(resolve => {
				this._refreshTokenRequest().then(result => resolve(result));
			});
		}

		// Les requêtes suivantes vont attendre que le refreshToken en cours se termine
		const result = await refreshTokenPromise;

		if (this.isExpired()) {
			refreshTokenPromise = undefined;

			this.setToken(result.token);
			this.setRefreshToken(result.refreshToken);
		}
	}
}

const isExpired = (exp: number) => (exp || 0) < Math.ceil(new Date().valueOf() / 1000);

export default new AuthStore();
