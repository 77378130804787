import { Model }                      from '@mathquis/modelx';
import { Connector }                  from '@mathquis/modelx';
import { ConnectorResult }            from '@mathquis/modelx';
import { ConnectorResults }           from '@mathquis/modelx';
import { Collection }                 from '@mathquis/modelx';
import Axios                          from 'axios';
import { AxiosInstance }              from 'axios';
import { AxiosResponse }              from 'axios';
import { AxiosError }                 from 'axios';
import { InternalAxiosRequestConfig } from 'axios';
import qs                             from 'qs';
import authStore                      from '@widesk-core/stores/authStore';
import devToolbarStore                from '@widesk-core/stores/devToolbarStore';
import _get                           from 'lodash/get';
import _set                           from 'lodash/set';
import _merge                         from 'lodash/merge';
import _chunk                         from 'lodash/chunk';
import { message }                    from '@widesk-ui/hooks/useMessage';
import ApiModel                       from '@widesk-core/models/ApiModel';
import { CacheSystem }                from '@widesk-core/models/apiCacheSystem';
import cacheSystem                    from '@widesk-core/models/apiCacheSystem';

const FILTER_URN_ARRAY_LIMIT = 100;
const MAX_ITEM_PER_PAGE = 100;  // MAX_ITEM_PER_PAGE doit être supérieur ou égale à FILTER_URN_ARRAY_LIMIT

export default class ApiConnector extends Connector {
	protected _authorizationHeaderName = 'X-Platform-Authorization';
	private readonly _client: AxiosInstance;

	// noinspection JSUnusedGlobalSymbols
	public resultTotalPath = 'hydra:totalItems';

	public constructor(options: any) {
		super(options);

		this._client = Axios.create({
			...options,
			headers: {
				'Accept': 'application/ld+json',
				'Content-Type': 'application/ld+json',
				'Accept-Language': 'fr',
				'X-Request-Context': 'admin',

				...options.headers,
			},
			responseType: 'json',
		});

		// Intercepteur de requêtes
		this._client.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
			if (authStore.token) {
				if (authStore.refreshToken && authStore.isExpired()) {
					await authStore.refreshTokenAsync();
				}

				// Ajout du token JWT token à la requête
				config.headers[this._authorizationHeaderName] = 'Bearer ' + authStore.token;
			}

			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			config.metadata = { startTime: new Date() };

			return config;
		});

		// Intercepteur de réponses
		this._client.interceptors.response.use(response => {
			devToolbarStore.addRequest({ startTime: _get(response, 'config.metadata.startTime'), endTime: new Date(), response });
			return response;
		}, error => {
			devToolbarStore.addRequest({ startTime: _get(error, 'config.metadata.startTime'), endTime: new Date(), response: error });
			return Promise.reject(error);
		});
	}

	// Model methods
	public async fetch(model: Model, options: ConnectorFetchOptions = {}): Promise<ConnectorResult> {
		const path = options.path || model.path;
		const cacheKey = this.options.baseURL + path + CacheSystem.transformObjectToCacheKey(options.params);

		const customOptions = {
			method: 'get',
			...options,
			headers: { 'X-LOCALE': options.translations, ...options.headers },
		};

		const cachedResult = cacheSystem.get(cacheKey);

		if (cachedResult) return new ConnectorResult(cachedResult.data.at(0) as never, {
			data: cachedResult.data.at(0),
		});

		const response = await this.request(model.path, customOptions);

		const cacheDuration = this._getCacheDuration(options, model.constructor);

		cacheSystem.setResponse(cacheKey, response, cacheDuration);

		return new ConnectorResult(response?.data, response);
	}

	public async list<T extends Model>(collection: Collection<T>, options: ConnectorListOptions = {}) {
		const cacheKey = this.options.baseURL + collection.path + CacheSystem.transformObjectToCacheKey(options.params);

		const customOptions = {
			method: 'get',
			...options,
			headers: { 'X-LOCALE': options.translations, ...options.headers },
		};

		const cacheResult = cacheSystem.get(cacheKey);

		if (cacheResult) return new ConnectorResults(cacheResult.data, {
			data: {
				'hydra:member': cacheResult.data,
				'hydra:totalItems': cacheResult.totalItems,
			},
		});

		const response = await this.requestList(collection.path, customOptions);
		const cacheDuration = this._getCacheDuration(options, collection.model);

		cacheSystem.setResponse(cacheKey, response, cacheDuration);

		return new ConnectorResults(response?.data?.['hydra:member'], response);
	}

	private _getCacheDuration(options: any, model: any) {
		if (typeof options['cache'] !== 'undefined') {
			return options['cache'] as number;
		}

		if (typeof model['cacheDuration'] !== 'undefined') {
			return model['cacheDuration'] as number;
		}

		return 0;
	}

	public async save(model: ApiModel, options: ConnectorSaveOptions = {}) {
		const staticModel = model.constructor as unknown as ApiModel;

		const data = options.patchAttributes || model.untransformedAttributes as Record<string, string>;

		const customOptions = {
			data: options.stringifyAttributes ? qs.stringify(data) : data,
			method: options.method ? options.method : model.id ? 'put' : 'post',
			...options,
			headers: {
				'Content-Type': options.stringifyAttributes ? 'application/x-www-form-urlencoded' : undefined,
				'X-LOCALE': options.translations || (data.translations ? '*' : undefined),
				...options.headers,
			},
		};

		const path = options.path || model.path;
		const response: any = await this.request(path, customOptions);
		const urnData = staticModel.urnData;

		// Lorsqu'un model est modifié, on supprime ce model du cache
		if (model.urn) cacheSystem.removeUrn(model.urn);
		// Lorsqu'un model est ajouté, on supprime tous les models pour cette resource du cache
		else if (urnData) cacheSystem.removeUrnStartWith(`${urnData.partition}:${urnData.service}:${urnData.resource}:`);

		return new ConnectorResult(response?.data || {}, response);
	}

	public async destroy(model: ApiModel, options: Record<string, unknown> = {}) {
		const response = await this.request(model.path, { method: 'delete', ...options });

		// Lorsqu'un model est supprimé, on supprime ce model du cache
		cacheSystem.removeUrn(model.urn);

		return new ConnectorResult(model.attributes, response);
	}

	/**
	 * Concaténation de plusieurs réponses de liste pour ne récupérer qu'une seule réponse
	 * @param responses
	 * @private
	 */
	private _concatListResponse(responses: (AxiosResponse)[]) {
		const newResponse = { ...responses.at(0) };
		_set(newResponse, 'data.hydra:member', responses.map(r => _get(r, 'data.hydra:member', [])).flat());
		return newResponse as AxiosResponse;
	}

	protected async requestList(path: string, options: Record<string, unknown> = {}): Promise<AxiosResponse | void> {
		const params: any = options.params || {};

		// On cherche un filtre "tableau d'urn" dans la liste qui dépasse la limite autorisée
		const filterUrnNameLimitExceeded = Object.keys(params).find(filterName => {
			const value = params[filterName];
			return filterName.endsWith('Urn') && Array.isArray(value) && value.length > FILTER_URN_ARRAY_LIMIT;
		});

		if (filterUrnNameLimitExceeded) {
			const urns = params[filterUrnNameLimitExceeded] as Urn[];

			// On découpe le tableau d'urn en plusieurs sous-tableaux
			const chunkUrnArrays = _chunk(urns, FILTER_URN_ARRAY_LIMIT);
			const responses = await Promise.all(chunkUrnArrays.map(urns => {
				return this.requestList(path, { options, params: { ...params, [filterUrnNameLimitExceeded]: urns } }) || {};
			}));

			return this._concatListResponse(responses.map(r => r || {} as AxiosResponse));
		}

		const hasPagination = typeof _get(options, 'params.itemsPerPage') !== 'undefined';

		const customOptions = _merge({ ...options }, {
			params: { itemsPerPage: _get(options, 'params.itemsPerPage', MAX_ITEM_PER_PAGE) }, // S'il n'y a pas d'ItemsPerPage, on en passe par défaut
		});

		const response = await this.request(path, customOptions);

		if (!hasPagination && response) { // Seulement pour les requêtes sans "itemsPerPage" (sans pagination)
			const total = _get(response, 'data.hydra:totalItems', 0);
			const firstRequestItems = _get(response, 'data.hydra:member', []);
			const items = [...firstRequestItems];

			if (items.length < total) { // Il y a moins d'items retournés que le total pour la requête
				const step = items.length;
				const pageQuantity = Math.ceil(total / step) - 1;

				// On requête toutes les autres pages à partir de la page 2
				const otherPagesResponses = await Promise.all(
					Array.from({ length: pageQuantity }, async (_, index) => {
						return this.request(path, { ...options, params: { ...options, itemsPerPage: step, page: index + 2, partial: true } });
					}),
				);

				// Construction d'une nouvelle response en fusionnant les responses de toutes les pages
				return this._concatListResponse([response, ...otherPagesResponses.map(r => r || {} as AxiosResponse)]);
			}
		}

		return response;
	}

	protected async request(path: string, options: Record<string, unknown> = {}) {
		return this._client(path.replace(/\/$/, ''), { ...options }).catch(err => {
			if (!options.noError) {
				this.onRequestError(err);
			} 
		});
	}

	protected async onRequestError(err: AxiosError) {
		if (Axios.isCancel(err)) {
			console.log(`Requête annulée`);
			return; // Si la requête est annulée on ne throw pas l'erreur
		}

		message.exception(err);
		throw err;
	}
}
