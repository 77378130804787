import './styles/Page.scss';

import React from 'react';
import View  from '@widesk-ui/components/View';
import theme from 'antd/lib/theme';

const { useToken } = theme;

export default function Page(props: { children: React.ReactNode }) {
	const { token } = useToken();

	return (
		<View className="widesk-page" bg={token.colorBgLayout} flex>
			{props.children}
		</View>
	);
}
