import { BoxProps }       from '@widesk-ui/components/Box';
import _uniqueId          from 'lodash/uniqueId';
import React              from 'react';
import { makeObservable } from 'mobx';
import { observable }     from 'mobx';
import { computed }       from 'mobx';
import { action }         from 'mobx';

export default class BoxStore {
	private readonly _id;

	@observable private _ctxTopRight?: () => React.ReactNode; // Permet d'afficher un composant en haut à droite de la box

	private _props: BoxProps;

	public constructor(props: BoxProps) {
		this._props = props;
		this._id = props.id || _uniqueId();

		makeObservable(this);
	}

	public setProps(props: BoxProps) {
		this._props = props;
	}

	public get props() {
		return this._props;
	}

	public get id() {
		return this._id;
	}

	@computed get ctxTopRight() {
		return this._ctxTopRight;
	}

	@action setCtxTopRight(el: React.ReactNode) {
		this._ctxTopRight = () => el;
	}
}
