import View     from '@widesk-ui/components/View';
import useTheme from '@widesk-ui/hooks/useTheme';
import React    from 'react';

export default function ViewPrimary(props: React.ComponentProps<typeof View>) {
	const { colorPrimary, colorPrimaryBgHover } = useTheme();

	return (
		<View
			color="white"
			bg={colorPrimary}
			hover={{
				...(props.onClick ? { bg: colorPrimaryBgHover } : {}),
				...props.hover,
			}}
			{...props}
		/>
	);
}
