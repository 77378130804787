import Form          from '@widesk-ui/components/form/Form';
import Input         from '@widesk-ui/components/form/Input';
import { useAuth }   from '@widesk-ui/hooks/useAuth';
import Box           from '@widesk-ui/components/Box';
import View          from '@widesk-ui/components/View';
import Button        from '@widesk-ui/components/Button';
import useThemeMode  from '@widesk-ui/hooks/useThemeMode';
import useTheme      from '@widesk-ui/hooks/useTheme';
import React         from 'react';
import InputPassword from '@widesk-ui/components/form/InputPassword';
import useDidMount   from '@widesk-core/hooks/useDidMount';

type LoginPageProps = {
	header?: React.ReactNode;
	footer?: React.ReactNode;
}

export default function LoginPage(props: LoginPageProps) {
	const { login } = useAuth();

	const { mode, setMode } = useThemeMode();
	const theme = useTheme();
	const isDark = mode === 'dark';

	useDidMount(() => document.title = 'Authentification');

	return (
		<View heightF center gap={theme.marginLG}>
			<Box style={{ width: 460, padding: theme.marginLG }}>
				<View gap={theme.marginXXL}>
					{props.header}

					<Form
						fields={{
							username: {
								span: 24,
								element: <Input id="username" placeholder="Votre identifiant" size="large" />,
								required: true,
							},
							password: {
								span: 24,
								element: <InputPassword id="password" placeholder="Votre mot de passe" size="large" />,
								required: true,
							},
						}}
						onSubmit={async ({ username, password }) => login(username as string, password as string)}
					>
						<Button size="large" block type="primary" htmlType="submit">
							Connectez-vous
						</Button>
					</Form>

					{props.footer}
				</View>
			</Box>

			<Button type="link" onClick={() => setMode(isDark ? 'light' : 'dark')}>
				Afficher le thème {isDark ? 'clair' : 'sombre'}
			</Button>
		</View>
	);
}
