import resolvable    from '@widesk-core/decorators/resolvable';
import path          from '@widesk-core/decorators/path';
import urnResource   from '@widesk-core/decorators/urnResource';
import cacheDuration from '@widesk-core/decorators/cacheDuration';

export default {
	cacheDuration,
	resolvable,
	path,
	urnResource,
};
