import SubscriptionStatusModel from '@models/account/SubscriptionStatusModel';
import doc                     from '@widesk-core/decorators/doc';
import model                   from '@widesk-core/decorators/model';
import AccountServiceModel     from '@/models/AccountServiceModel';
import TimestampAble           from '@widesk-core/models/traits/TimestampAble';
import Blamable                from '@/models/traits/Blamable';

@model.urnResource('subscription')
@doc.path('/subscriptions/{?id}')
export default class SubscriptionModel extends Blamable(TimestampAble(AccountServiceModel)) {
	private declare _tsFilters: {
		'accountSubscriptions': id;
		'subscriptionConfigs': id;
		'subscriptionStatus': id;
		'subscriptionStatus.reference': Account_SubscriptionStatusReference;
		'subscriptionStatusHistories': id;
	};

	private declare _tsSorts: {
		'id': string;
		'label': string;
	};	

	@doc.string declare label: string;
	@doc.string declare reference: Account_SubscriptionReference;
	@doc.model(SubscriptionStatusModel) declare subscriptionStatus: SubscriptionStatusModel;
}