import View               from '@widesk-ui/components/View';
import theme              from 'antd/lib/theme';
import AntdBreadcrumb     from 'antd/lib/breadcrumb';
import { useMatches }     from 'react-router-dom';
import { HomeOutlined }   from '@ant-design/icons';
import breadcrumbBarStore from '@widesk-ui/stores/breadcrumbBarStore';
import { observer }       from 'mobx-react';

const { useToken } = theme;

const BreadcrumbBar = observer(() => {
	if (!breadcrumbBarStore.enabled) return null;

	return <BreadcrumbBarContent />;
});

export const BreadcrumbBarContent = observer(() => {
	const { token } = useToken();
	const matches = useMatches();

	const crumbs = matches
		.filter((match) => Boolean((match.handle as any)?.crumb))
		.map((match) => (match.handle as any)?.crumb(match.data));

	const isHome = matches.every(m => m.pathname === '/');

	const routes = [{
		href: isHome ? undefined : '/',
		title: isHome ? ' Accueil' : <HomeOutlined />,
	}, ...crumbs.map(title => ({ title }))];

	if (!isHome && !crumbs.length && !breadcrumbBarStore.rightComponent) {
		return null;
	}

	document.title = crumbs.at(-1) || `Accueil`;

	return (
		<View
			bg={token.colorBgContainer}
			minHeight={50}
			centerV
			paddingH={token.marginLG}
			separatorB
			row
			spread
		>
			<AntdBreadcrumb items={routes} style={{ color: 'red' }} />

			{!!breadcrumbBarStore.rightComponent && breadcrumbBarStore.rightComponent()}
		</View>
	);
});

export default BreadcrumbBar;
