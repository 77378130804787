import '@widesk-ui/components/styles/EnvHeadband.scss';

import View     from '@widesk-ui/components/View';
import useTheme from '@widesk-ui/hooks/useTheme';

const ENABLED = window.FRONT_CONFIG.displayEnvApi || __LOCAL__;

const EnvHeadband = ENABLED ? () => {
	const theme = useTheme();

	const colors = {
		local: '#000',
		dev: theme.colorSuccess,
		demo: theme.colorPrimary,
		preprod: theme.colorWarning,
		prod: theme.colorError,
	};

	const apiEnv = window.FRONT_CONFIG.env;

	return (
		<View bg={colors[apiEnv]} className="widesk-env-headband">
			API : {apiEnv}
		</View>
	);
} : () => null;

export default EnvHeadband;
