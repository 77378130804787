import '@widesk-ui/styles/Layout.scss';

import { Outlet }  from 'react-router-dom';
import AntdLayout  from 'antd/lib/layout/layout';
import View        from '@widesk-ui/components/View';
import DevToolbar  from '@widesk-ui/components/DevToolbar';
import EnvHeadband from '@widesk-ui/components/EnvHeadband';
import useMessage  from '@widesk-ui/hooks/useMessage';
import useNavigate from '@widesk-ui/hooks/useNavigate';

export default function Layout() {
	useNavigate();

	const [contextHolder] = useMessage();

	return (
		<View height="100vh">
			{contextHolder}

			<AntdLayout className="widesk-layout" style={{ height: '100%' }}>
				<Outlet />

				<EnvHeadband />
			</AntdLayout>

			<DevToolbar />
		</View>
	);
}
