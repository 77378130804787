import { ResolvableModelClass }        from '@mathquis/modelx-resolvables/lib/types/AbstractResolvableModel';
import AbstractResolvableModel         from '@mathquis/modelx-resolvables/lib/types/AbstractResolvableModel';
import { IResolvableDecoratorOptions } from '@mathquis/modelx-resolvables/lib/types/AbstractResolvableModel';
import resolvable                      from '@widesk-core/decorators/resolvable';

export const modelUrn = (
	modelClass: ResolvableModelClass<AbstractResolvableModel>,
	options: IResolvableDecoratorOptions = {},
) => {
	return function (target: AbstractResolvableModel, key: string) {
		return resolvable(modelClass, { attributeName: key + 'Urn', ...options })(target, key);
	};
};
