export default ({
	partition,
	service,
	resource,
	identifier,
} : {
	partition: string,
	service: string,
	resource: string,
	identifier: string,
}) => {
	return `${partition}:${service}:${resource}:${identifier}`;
};
